import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'
import { IStore } from '@store/store'
import { CheckoutBackToCart } from '@components/views/checkoutPage/components/checkoutBackToCart/checkoutBackToCart'
import { CheckoutName } from '@components/views/checkoutPage/components/checkoutName/checkoutName'
import { CartData } from '@components/views/cartPage/components/cartData/cartData'
import { CheckoutNav } from '@components/views/checkoutPage/components/checkoutNav/checkoutNav'
import { CheckoutDelivery } from '@components/views/checkoutPage/components/checkoutDelivery/checkoutDelivery'
import { CheckoutInfo } from '@components/views/checkoutPage/components/checkoutInfo/checkoutInfo'
import { CheckoutConfirm } from '@components/views/checkoutPage/components/checkoutConfirm/checkoutConfirm'
import { StyledCheckoutPage } from '@components/views/checkoutPage/styles/checkoutPage.styled'

import { commonStyle } from '../../../styles'


export function CheckoutPage() {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledCheckoutPage>
        <div className="checkout-container">
          <CheckoutBackToCart />
          <CheckoutName />
          <CartData isCartPage={false}/>
          <CheckoutNav />

          <Routes>
            <Route path="delivery" element={<CheckoutDelivery />} />
            <Route path="info" element={<CheckoutInfo />} />
            <Route path="confirm" element={<CheckoutConfirm />} />
          </Routes>
        </div>
      </StyledCheckoutPage>
    </ThemeProvider>
)
}
