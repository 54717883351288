import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { endpoints } from '@endpoints/endpoints'
import {
  CatalogItem,
  GroupItem, PriceLimit,
  Product,
  ProductFilters,
  ProductType,
  TableView,
} from '@endpoints/endpoints/products/types'
import { IStore } from '@store/store'

export interface IProductsState {
  products: Product[]
  filters: ProductFilters
  catalog: CatalogItem[]
  fabrics: string[]
  groups: GroupItem[]
  tableView: TableView
  isProductsUpdate: boolean
  isCategoriesUpload: boolean
  totalCount: number | null // null нужен, чтобы показать что при поиске заргузке товаров произошла ошибка
  priceLimits: PriceLimit[]
}

export const initialFilters: ProductFilters = {
  productType: 'SPB',
  maybeGroup: '',
  type: '',
  maybeFabrics: 'all',
  isNew: null,
  isFavorites: null,
  isInStock: null,
  maybePriceStart: null,
  maybePriceEnd: null,
  searchText: null,
  pageNumber: 0,
  pageSize: 10
}

const initialState: IProductsState = {
  products: [],
  filters: { ...initialFilters },
  catalog: [],
  fabrics: [],
  groups: [],
  tableView: 'list',
  isProductsUpdate: false,
  isCategoriesUpload: false,
  totalCount: 0,
  priceLimits: [
    {
      shippingPointName: 'СПБ',
      minPrice: 0,
      maxPrice: 0,
      currency: 'RUB'
    },
    {
      shippingPointName: 'Гуанчжоу',
      minPrice: 0,
      maxPrice: 0,
      currency: 'CNY'
    }
  ]
}

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      const products = action.payload

      // Реализовано дедублирование массива продуктов по свойству 'art'
      state.products = [ ...state.products, ...products ].reduce((acc, cur) => {
        if(!acc.find((item: Product) => item.art === cur.art)) {
          acc.push(cur)
        }

        return acc
      }, [])
    },
    resetProducts: (state) => {
      state.products = []
    },
    updateFilter: (state, action: { payload: ProductFilters }) => {
      state.filters = action.payload
    },
    setCatalog: (state, action: { payload: CatalogItem[] }) => {
      state.catalog = action.payload
    },
    setFabrics: (state, action: { payload: string[] }) => {
      state.fabrics = action.payload
    },
    setPriceLimits: (state, action: { payload: PriceLimit[] }) => {
      state.priceLimits = action.payload
    },
    setGroups: (state, action: { payload: GroupItem[] }) => {
      state.groups = action.payload
    },
    setProductsUpdateStatus: (state, action: { payload: boolean }) => {
      state.isProductsUpdate = action.payload
    },
    setCategoriesUploadStatus: (state, action: { payload: boolean }) => {
      state.isCategoriesUpload = action.payload
    },
    setTableView: (state, action: { payload: TableView }) => {
      state.tableView = action.payload
    },
    setPage: (state, action: { payload: number }) => {
      state.filters.pageNumber = action.payload
    },
    setTotalCount: (state, action: { payload: number | null }) => {
      state.totalCount = action.payload
    }
  }
})

//Получение списка товаров
export const getProductsThunk = createAsyncThunk(
  'products/products',
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setProductsUpdateStatus(true))

      const filters = (getState() as IStore).products.filters
      const productData = await endpoints.products.prices(filters)

      if(productData) {
        dispatch(setProducts(productData.resource))
        dispatch(setTotalCount(productData.totalResourceCount))
      }
    } catch (error) {
      console.error(error)
      dispatch(setTotalCount(null)) // Ставим totalCount в null, чтобы отличить ошибку запроса
      throw error
    } finally {
      dispatch(setProductsUpdateStatus(false))
    }
  }
)

//Обновление фильтров товаров
export const updateProductFilterThunk = createAsyncThunk(
  'products/filter',
  async (filters: ProductFilters, { dispatch }) => {
    try {
      dispatch(updateFilter(filters)) //Сначала обновляем фильтры для запроса
      dispatch(resetProducts()) //Затем сбрасываем все старые товары
      dispatch(setPage(0)) //Обнуляем страницу для запроса
      dispatch(getProductsThunk()) //Получаем новые товары
    } catch (error) {
      console.error(error)
      throw error
    }
  }
)

//Установка текущей страницы товаров
export const setPageNumberThunk = createAsyncThunk(
  'products/pageNumber',
  async (pageNumber: number, { dispatch, getState }) => {
    try {
      const filters = (getState() as IStore).products.filters
      const count = (getState() as IStore).products.totalCount

      //Проверка, на то, чтобы подгружать страницы только тогда, когда не все товары получены и нет ошибки загрузки страниц (count === null)
      if ((count && filters.pageNumber * filters.pageSize < count) || count === 0) {
        dispatch(setPage(pageNumber))
        await dispatch(getProductsThunk())
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }
)

//Получение каталога товаров
export const getProductCatalogThunk = createAsyncThunk(
  'products/catalog',
  async (_, { dispatch }) => {
    try {
      dispatch(setCategoriesUploadStatus(true))

      const catalog = await endpoints.products.catalog()

      dispatch(setCatalog(catalog))
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      dispatch(setCategoriesUploadStatus(false))
    }
  }
)

//Получение списка групп для фильтра группы
export const getGroupsForFilterThunk = createAsyncThunk(
  'products/groups',
  async (type: ProductType, { dispatch }) => {
    try {
      const groups = await endpoints.products.groups(type)

      dispatch(setGroups(groups))
    } catch (error) {
      console.error(error)
      throw error
    }
  }
)

//Получение списка фабрик для фильтра фабрика
export const getProductFabricsThunk = createAsyncThunk(
  'products/fabrics',
  async (_, { dispatch }) => {
    try {
      const fabrics = await endpoints.products.fabrics()

      dispatch(setFabrics(fabrics))
    } catch (error) {
      console.error(error)
      throw error
    }
  }
)

//Получение списка лимитов цен для фильтра
export const getPriceLimitsThunk = createAsyncThunk(
  'products/priceLimits',
  async (_, { dispatch }) => {
    try {
      const priceLimits = await endpoints.products.priceLimits()

      dispatch(setPriceLimits(priceLimits))
    } catch (error) {
      console.error(error)
      throw error
    }
  }
)

//Смена списка товаров на плитку товаров
export const setTableViewThunk = createAsyncThunk(
  'products/tableView',
  async (viewType: TableView, { dispatch }) => {
    try {
      dispatch(setTableView(viewType))
    } catch (error) {
      console.error(error)
      throw error
    }
  }
)

export const {
  setProducts,
  resetProducts,
  setProductsUpdateStatus,
  updateFilter,
  setCatalog,
  setFabrics,
  setPriceLimits,
  setGroups,
  setCategoriesUploadStatus,
  setTableView,
  setPage,
  setTotalCount
} = productsSlice.actions

export default productsSlice.reducer
