import React from 'react'
import { useSelector } from 'react-redux'
import { IStore, useAppDispatch } from '@store/store'
import Icons from '@icons/icons'
import { ThemeProvider } from 'styled-components'
import { StyledCheckoutDelivery } from '@components/views/checkoutPage/components/checkoutDelivery/styles/checkoutDelivery.styled'
import { DeliveryMethod } from '@endpoints/endpoints/checkout/type'
import { updateField } from '@store/checkout'

import { commonStyle } from '../../../../../styles'

export function CheckoutDelivery() {
  const dispatch = useAppDispatch()

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  const deliveryMethod = useSelector((state: IStore) => state.checkout.receiveInformation.deliveryMethod)

  const setDeliveryMethod = (delivery: DeliveryMethod) => {
    dispatch(updateField({ fieldPath: 'receiveInformation.deliveryMethod', value: delivery }))
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledCheckoutDelivery>
        <h2>Выберите способ доставки</h2>

        <div className="delivery-row">
          <div className="cart-container">
            <div
              className={`delivery-cart ${deliveryMethod === 'CDEK' && 'active'}`}
              onClick={() => setDeliveryMethod('CDEK')}
            >
              <Icons name="sdek" color="#fff" size="20" className="icon" />
              <p>СДЭК до отделения</p>
            </div>

            <div
              className={`delivery-cart ${deliveryMethod === 'CDEK_DOOR' && 'active'}`}
              onClick={() => setDeliveryMethod('CDEK_DOOR')}
            >
              <Icons name="sdek" color="#fff" size="20" className="icon" />
              <p>СДЭК до двери</p>
            </div>
          </div>
        </div>

        <div className="delivery-row">
          <p className='delivery-row-name'>по Санкт-Петербургу:</p>

          <div className="cart-container">
            <div
              className={`delivery-cart ${deliveryMethod === 'COURIER' && 'active'}`}
              onClick={() => setDeliveryMethod('COURIER')}
            >
              <Icons name="courier" color="#fff" size="20" className="icon" />
              <p>Курьер</p>
            </div>

            <div
              className={`delivery-cart ${deliveryMethod === 'PICKUP' && 'active'}`}
              onClick={() => setDeliveryMethod('PICKUP')}
            >
              <Icons name="self-pickup" color="#fff" size="20" className="icon" />
              <p>Самовывоз</p>
            </div>
          </div>
        </div>

        <div className="delivery-row">
          <p className='delivery-row-name'>для заказов от 50 кг:</p>

          <div className="cart-container">
            <div
              className={`delivery-cart ${deliveryMethod === 'ZHELDOR_EKSPEDITSIYA' && 'active'}`}
              onClick={() => setDeliveryMethod('ZHELDOR_EKSPEDITSIYA')}
            >
              <Icons name="zhde" color="#fff" size="20" className="icon" />
              <p>ЖелДорЭкспедиция</p>
            </div>

            <div
              className={`delivery-cart ${deliveryMethod === 'PEC' && 'active'}`}
              onClick={() => setDeliveryMethod('PEC')}
            >
              <Icons name="pek" color="#fff" size="20" className="icon" />
              <p>ПЭК</p>
            </div>
          </div>
        </div>
      </StyledCheckoutDelivery>
    </ThemeProvider>
  )
}
