import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'
import { IStore } from '@store/store'
import { DeliveryMethod, PaymentMethod } from '@endpoints/endpoints/checkout/type'
import { ProductType } from '@endpoints/endpoints/products/types'
import { endpoints } from '@endpoints/endpoints'
// @ts-ignore
import { Orders } from '@endpoints/endpoints/cart/type'

export interface ICheckoutState {
  orders: Orders
  productType: ProductType
  receiveInformation: {
    receiver: {
      name?: string
      phoneNumber?: string
    }
    address: {
      country?: string
      city?: string
      street?: string
      buildingNumber?: string
      apartmentNumber?: string
      flatNumber?: string
      deliveryId?: string
      comment?: string
    }
    paymentMethod: PaymentMethod
    deliveryMethod: DeliveryMethod
    isDefault: boolean
  }
}

const initialState: ICheckoutState = {
  orders: [],
  productType: 'SPB',
  receiveInformation: {
    receiver: {
      name: undefined,
      phoneNumber: undefined
    },
    address: {
      country: undefined,
      city: undefined,
      street: undefined,
      apartmentNumber: undefined,
      buildingNumber: undefined,
      flatNumber: undefined,
      deliveryId: undefined,
      comment: undefined
    },
    paymentMethod: 'BY_BILL',
    deliveryMethod: 'CDEK',
    isDefault: false
  }
}

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    updateField: (state, action: PayloadAction<{ fieldPath: string; value: any }>) => {
      const { fieldPath, value } = action.payload
      const pathArray = fieldPath.split('.')
      setNestedValue(state, pathArray, value)
    },
    setOrder: (state, action: PayloadAction<Orders>) => {
      state.orders = action.payload
    },
    deleteOrders: (state) => {
      state.orders = []
    },
    updateProductType: (state, action: PayloadAction<ProductType>) => {
      state.productType = action.payload
    },
  },
})

const setNestedValue = (obj: any, path: string[], value: any) => {
  let current = obj
  for (let i = 0; i < path.length - 1; i++) {
    if (!(path[i] in current)) {
      current[path[i]] = {}
    }
    current = current[path[i]]
  }
  current[path[path.length - 1]] = value
}

export const setOrderThunk = createAsyncThunk(
  'checkout/setOrder',
  async (order: Orders, { dispatch }) => {
    try {
      dispatch(setOrder(order))
    } catch (error) {
      console.error(error)
    }
  },
)

export const checkoutOrderThunk = createAsyncThunk(
  'checkout/checkout',
  async (_, { getState }) => {
    try {
      const data = (getState() as IStore).checkout
      await endpoints.checkout.checkoutOrder(data.productType, data.orders, data.receiveInformation)
    } catch (error) {
      console.error(error)
    }
  },
)

export const { updateField, setOrder, updateProductType, deleteOrders } = checkoutSlice.actions
export default checkoutSlice.reducer
