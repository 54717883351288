import styled from 'styled-components'

import { commonStyle } from '../../../../../../../../../../../../styles'

export const StyledItemPrice = styled.div.attrs(() => ({
  className: 'item-price',
}))`
  display: flex;
  gap: 0.5rem;
  width: 100%;
  font-size: ${() => commonStyle.fonts.fs11};
  
  .standard-price {
    min-width: 1.875rem;
  }
  
  .old-price {
    color: ${() => commonStyle.colors.grey400};
    text-decoration: line-through;
  }
  
  .sale-price {
    color: ${() => commonStyle.colors.red};
  }
`
