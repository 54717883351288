import styled from 'styled-components'

import { commonStyle } from '../../../../../../../../../../../../styles'

export const StyledItemSale = styled.div.attrs(() => ({
  className: 'item-sale',
}))`
  width: 100%;
  font-size: ${() => commonStyle.fonts.fs12};

  @media screen and (max-width: 1201px) {
    width: 4rem !important;
  }
`
