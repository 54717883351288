import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { IStore } from '@store/store'
import { ItemNameProps } from '@components/views/teaPage/components/products/components/tableView/components/tableItem/components/itemName/types/types'
import { StyledItemName } from '@components/views/teaPage/components/products/components/tableView/components/tableItem/components/itemName/styles/itemName.styled'

import { commonStyle } from '../../../../../../../../../../../styles'

export function ItemName(props: ItemNameProps) {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledItemName $inStoke={props.inStoke}>
        <div
          className='table-subitem name'
          onClick={!props.disabled ? props.onExpandClick : () => {}}
        >
          {props.fullName}
        </div>
      </StyledItemName>
    </ThemeProvider>
  )
}
