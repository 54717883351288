import styled from 'styled-components'

import { commonStyle } from '../../../../../styles'

export const StyledDone = styled.div.attrs(() => ({
  className: 'done',
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.75rem;
  width: 400px;
  padding: 4rem 2rem;

  h2 {
    color: ${props => props.theme.color};
    font-size: ${() => commonStyle.fonts.fs32};
    font-weight: ${() => commonStyle.fontStyles.fw600};
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
    p {
      text-align: center;
      color: ${props => props.theme.secondColor};
    }

    .link-text {
      font-size: ${() => commonStyle.fonts.fs16};
      font-weight: ${() => commonStyle.fontStyles.fw600};
    }

    .time-text {
      font-size: ${() => commonStyle.fonts.fs12};
    }
  }
`
