import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { IStore } from '@store/store'
import { DoneProps } from '@components/authorization/components/done/types/types'
import { StyledReset } from '@components/authorization/components/reset/styles/reset.styled'
import Icons from '@icons/icons'

import { commonStyle } from '../../../../styles'

export function Reset(props: DoneProps) {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  const close = () => {
    props.onClose()
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledReset>
        <Icons name="check" color={commonStyle.colors.red} size="64" className="icon" />

        <h2>Пароль сброшен</h2>
        <p className='attention'>Для продолжения проверьте свою почту</p>
        <p>Приятных покупок</p>

        <Button className='confirm-button' onClick={close}>Продолжить</Button>
      </StyledReset>
    </ThemeProvider>
  )
}
