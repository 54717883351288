import styled from 'styled-components'

import { commonStyle } from '../../../../../../../../../../../../styles'

type StyledTableItemProps = {
  $inStoke: boolean
}

export const StyledItemName = styled.div.attrs(() => ({
  className: 'item-name',
}))<StyledTableItemProps>`
  padding-left: 1.25rem;
  color: ${props => props.$inStoke ? props.theme.color : commonStyle.colors.grey400};
  cursor: pointer;
  //cursor: ${props => props.$inStoke ? 'pointer' : 'text'};
`
