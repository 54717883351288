import { Product, ProductType, UnitTypeSelector } from '@endpoints/endpoints/products/types'
import { SpecialCondition } from '@endpoints/endpoints/cart/type'
// @ts-ignore
import { Cart } from '@types/cartTypes'
import { User } from '@endpoints/endpoints/auth/types'
import { VIP_SPB_SALE } from '@utils/consts'

export const getPriceForChina = (productNumber: number | undefined, product: Product, unit: UnitTypeSelector) => {
  if(productNumber && productNumber >= product.valueInBox && unit !== 'BOX'){
    //Если есть продукт и количество продукта больше чем количество в стандартной коробке и единица измерения не коробка, то возвращаем цену за коробку
    return product.priceBoxCny
  } else if(productNumber && productNumber * product.valueGram >= 5000 && unit !== 'BOX') {
    //Если есть продукт, суммарная масса больше 5 кг и единица измерения не коробка
    return product.priceFiveKgCny
  } else {
    return product.units.find(unitItem => unitItem.type === unit)?.priceForUnit ?? product.price
  }
}

export const getPriceForSpb = (product: Product, unit: UnitTypeSelector) => {
  const priceForUnit = product.units.find(unitItem => unitItem.type === unit)?.priceForUnit ?? product.price
  const saleForUnit = product.spbDiscountPrice ? Number(product.spbDiscountPrice) : 0

  if(saleForUnit) {
    return priceForUnit - (priceForUnit - saleForUnit)
  } else {
    return priceForUnit
  }
}

//Расчёт скидки для китайского прайса
export const getChinaSale = (productNumber: number | undefined, product: Product, unit: UnitTypeSelector) => {
  const priceForUnit = product.units.find(unitItem => unitItem.type === unit)?.priceForUnit ?? product.price
  const saleForUnit = product.units.find(unitItem => unitItem.type === unit)?.saleForUnit ?? 0

  if(unit === 'BOX' && productNumber) {
    return productNumber * saleForUnit
  } else if(productNumber) {
    return (productNumber * priceForUnit) - (productNumber * getPriceForChina(productNumber, product, unit))
  } else {
    return 0
  }
}

// Расчёт скидки для СПБ прайса
export const getSpbSale = (
  productNumber: number | undefined,
  product: Product,
  unit: UnitTypeSelector,
  user: User | null,
  percentageSale?: number
) => {
  if (!productNumber) return 0

  const priceForUnit = product.units.find(unitItem => unitItem.type === unit)?.priceForUnit ?? product.price

  const hasDiscountPrice = Boolean(product.spbDiscountPrice)
  const priceWithSaleForUnit = hasDiscountPrice ? Number(product.spbDiscountPrice) : priceForUnit

  let discountPerUnit = 0

  // Скидка за скидочную позицию
  if (hasDiscountPrice) {
    discountPerUnit += priceForUnit - priceWithSaleForUnit
  }

  // Скидка VIP клиента
  if (user?.status === 'VIP') {
    discountPerUnit += priceWithSaleForUnit * VIP_SPB_SALE
  }

  // Скидка за объём товара
  if (percentageSale) {
    discountPerUnit += priceWithSaleForUnit * (percentageSale / 100)
  }

  return discountPerUnit * productNumber
}

export const getPositionSpbDiscountSale = (
  productNumber: number | undefined,
  product: Product,
  unit: UnitTypeSelector,
) => {
  if (!productNumber) return 0

  const priceForUnit = product.units.find(unitItem => unitItem.type === unit)?.priceForUnit ?? product.price
  const hasDiscountPrice = Boolean(product.spbDiscountPrice)
  const priceWithSaleForUnit = hasDiscountPrice ? Number(product.spbDiscountPrice) : priceForUnit
  let discountPerUnit = 0

  if (hasDiscountPrice) {
    discountPerUnit += priceForUnit - priceWithSaleForUnit
  }

  return discountPerUnit * productNumber
}

//Расчёт общей цены корзины для региона (Регион не обязателен, т.к. проверяю только СПБ, если пусто, то Китай)
export const getCartTotalPrice = (cart: Cart, region?: string) => {
  const items = Object.values(cart)

  if(region === 'СПБ') {
    const SPBItems = items.filter(item => item.region === region)

    return SPBItems.reduce((accumulator, currentValue) => {
      if(currentValue.price && currentValue.number) {
        return accumulator + (currentValue.price * currentValue.number)
      } else {
        return accumulator
      }
    }, 0)
  } else {
    const ChinaItems = items.filter(item => item.region !== 'СПБ')

    return ChinaItems.reduce((accumulator, currentValue) => {
      if(currentValue.price && currentValue.number) {
        return accumulator + (currentValue.price * currentValue.number)
      } else {
        return accumulator
      }
    }, 0)
  }
}

//Расчёт общего веса корзины для региона (Регион не обязателен, т.к. проверяю только СПБ, если пусто, то Китай)
export const getCartTotalWeight = (cart: Cart, region?: string) => {
  const items = Object.values(cart)

  if(region === 'СПБ') {
    const SPBItems = items.filter(item => item.region === region)

    return SPBItems.reduce((accumulator, currentValue) => {
      if(currentValue.unit && currentValue.number && currentValue.item) {
        if(currentValue.unit === 'PCS') {
          return accumulator + (currentValue.item.valueGram * currentValue.number / 1000)
        } else if(currentValue.unit === 'KG') {
          return accumulator + (currentValue.number)
        } else {
          return accumulator
        }
      } else {
        return accumulator
      }
    }, 0)
  } else {
    const ChinaItems = items.filter(item => item.region !== 'СПБ')

    return ChinaItems.reduce((accumulator, currentValue) => {
      if(currentValue.unit && currentValue.number && currentValue.item) {
        if(currentValue.unit === 'PCS') {
          return accumulator + (currentValue.item.valueGram * currentValue.number / 1000)
        } else if(currentValue.unit === 'BOX') {
          return accumulator + (currentValue.item.valueGram * currentValue.item.valueInBox * currentValue.number / 1000)
        } else if(currentValue.unit === 'KG') {
          return accumulator + (currentValue.number)
        } else if(currentValue.unit === 'CZN') {
          return accumulator + (currentValue.number / 2)
        } else {
          return accumulator
        }
      } else {
        return accumulator
      }
    }, 0)
  }
}

//Расчёт общей скидки для корзины
export const getCommonCartSale = (
  cart: Cart, // Корзина товаров
  productType: ProductType, // Регион
  user: User | null, // Пользователь для определения статуса клиента
  percentageSale?: number // Процента скидки за объём закаказа
) => {
  if(productType === 'CHINA') {
    return Object.values(cart)
      .filter(currentValue => currentValue.item)
      .reduce((accumulator, currentValue) => {
        return accumulator + getChinaSale(
          currentValue.number,
          currentValue.item as Product,
          currentValue.unit ? currentValue.unit : (currentValue.item as Product).units[0].type
        )
      }, 0)
  } else {
    return Object.values(cart)
      .filter(currentValue => currentValue.item)
      .reduce((accumulator, currentValue) => {
        return accumulator + getSpbSale(
          currentValue.number,
          currentValue.item as Product,
          currentValue.unit ? currentValue.unit : (currentValue.item as Product).units[0].type,
          user,
          percentageSale
        )
      }, 0)
  }
}

//Расчёт процента скидки от суммы заказа
export const getMaxPercentage = (specialConditions: SpecialCondition[], price: number) => {
  let currentPercentage = 0

  specialConditions.forEach(conditionValue => {
    if(price >= conditionValue.conditionMinMoney) {
      currentPercentage = conditionValue.salePercentage
    }
  })

  return currentPercentage
}

// Суммарная скидка за все позиции товара (Скидочные позиции)
export const getDiscountItemsSales = (
  cart: Cart, // Корзина товаров
  productType: ProductType, // Регион
) => {
  if(productType === 'CHINA') {
    return 0
  } else {
    return Object.values(cart)
      .filter(currentValue => currentValue.item)
      .reduce((accumulator, currentValue) => {
        return accumulator + getPositionSpbDiscountSale(
          currentValue.number,
          currentValue.item as Product,
          currentValue.unit ? currentValue.unit : (currentValue.item as Product).units[0].type,
        )
      }, 0)
  }
}

//Расчёт цвета для артикулов в зависимости от региона и параметров товара
//Возвращаю имена классов согласно приоритетов статусов от бизнеса
//Скидка приоритетнее новинки
export const getArticleColor = (product: Product) => {
  if(product.shippingPoint === 'СПБ') {
    if(product.isNewPosition && product.inStock) {
      return 'isNew'
    } else if(product.spbDiscountPrice && product.inStock) {
      return 'discount'
    } else {
      return ''
    }
  } else {
    if(product.isNewPosition && product.inStock) {
      return 'isNew'
    } else {
      return ''
    }
  }
}
