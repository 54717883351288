import { useEffect, useState } from 'react'

export function useAppAvailability(): boolean {
  const [isAvailable, setIsAvailable] = useState(true)

  // Функция для проверки доступности приложения
  const checkAppAvailability = () => {
    const userAgent = navigator.userAgent || navigator.vendor
    const isAndroid = /android/i.test(userAgent)
    const isIOS = /iPad|iPhone|iPod/.test(userAgent)
    const screenWidth = window.innerWidth

    if (isAndroid || isIOS || screenWidth < 760) {
      setIsAvailable(false)
    } else {
      setIsAvailable(true)
    }
  }

  useEffect(() => {
    checkAppAvailability()

    const handleResize = () => {
      checkAppAvailability()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isAvailable
}
