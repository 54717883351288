import styled from 'styled-components'

import { commonStyle } from '../../../../../../styles'

export const StyledCheckoutDelivery = styled.div.attrs(() => ({
  className: 'checkout-delivery',
}))`
  grid-area: checkout;

  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 1rem;

  h2 {
    font-size: ${() => commonStyle.fonts.fs20};
    font-weight: ${() => commonStyle.fontStyles.fw600};
  }

  .delivery-row {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .delivery-row-name {
      font-size: ${() => commonStyle.fonts.fs16};
    }

    .cart-container {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      .delivery-cart {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        width: 15.625rem;
        height: 3.75rem;
        background-color: ${() => commonStyle.colors.white};
        border-radius: ${() => commonStyle.radii.radius8};
        cursor: pointer;
        box-shadow: 0 0 24px 0 ${() => commonStyle.colors.grey300};
        border: none;
        outline: none;
        transition: box-shadow ${() => commonStyle.times.time5};

        p {
          line-height: 24px;
          font-size: ${() => commonStyle.fonts.fs16};
          font-weight: ${() => commonStyle.fontStyles.fw600};
          font-family: 'MontserratFont', sans-serif;
        }

        &:hover {
          box-shadow: 0 0 28px 0 ${() => commonStyle.colors.grey400};
          transition: box-shadow ${() => commonStyle.times.time5};
        }
      }

      .active {
        border: 2px solid ${() => commonStyle.colors.red}
      }
    }
  }

  .next-button {
    width: 10rem;
    height: 3rem;
    background-color: ${() => commonStyle.colors.red};
    color: ${() => commonStyle.colors.white};
    font-size: ${() => commonStyle.fonts.fs14};
    font-weight: ${() => commonStyle.fontStyles.fw600};
    font-family: 'MontserratFont', sans-serif;
    border-radius: ${() => commonStyle.radii.radius8};
    text-transform: inherit;
    box-shadow: none;
    transition: background-color ${() => commonStyle.times.time3};

    &:hover {
      background-color: ${() => commonStyle.colors.darkRed};
      transition: background-color ${() => commonStyle.times.time3};
    }
  }
`
