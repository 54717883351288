import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { IStore } from '@store/store'
import Icons from '@icons/icons'
import { StyledPlugPage } from '@components/views/plugPage/styles/plugPage.styled'

import { commonStyle } from '../../../styles'

export function PlugPage() {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)
  const spbPriceFile = useSelector((state: IStore) => state.products.spbPriceFile)
  const chinaPriceFile = useSelector((state: IStore) => state.products.chinaPriceFile)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledPlugPage>
        <div className="logo">
          <Icons name="logo" color="" size="58" className="icon" />
          <h2>Чай без церемоний</h2>
        </div>

        <h2>Приложение недоступно на вашем устройстве</h2>
        <p className="info">Пожалуйста, воспользуйтесь десктопной версией сайта</p>

        <div className="files">
          <h3>Файлы для загрузки:</h3>
          {spbPriceFile && (
            <div>
              <a className="link" href={spbPriceFile} download="spb-price-file.zip">Скачать файл SPB</a>
            </div>
          )}
          {chinaPriceFile && (
            <div>
              <a className="link" href={chinaPriceFile} download="china-price-file.zip">Скачать файл CHINA</a>
            </div>
          )}
        </div>
      </StyledPlugPage>
    </ThemeProvider>
  )
}
