import { AxiosResponse } from 'axios'
import { axiosInstance } from '@endpoints/axios'
import { PaginationResult } from '@endpoints/types'
import {
  CatalogItem,
  GroupItem,
  ImageRequestType, ImageResponseType,
  ImageType, PriceLimit,
  Product,
  ProductFilters,
  ProductType,
} from '@endpoints/endpoints/products/types'

export const products = {
  async prices(filters?: ProductFilters): Promise<PaginationResult<Product[]> | undefined> {
    try {
      const params = {
        priceType: filters?.productType,
        catalogItemType: filters?.type || null,
        group: filters?.maybeGroup || null,
        fabric: (filters?.maybeFabrics && filters?.maybeFabrics !== 'all') ? filters.maybeFabrics : null, //Ищем все фабрики если по дефолту выбран фильтр "Фабрика"
        isNew: filters?.isNew ? filters.isNew : null,
        inStock: (filters?.isInStock && (filters?.productType === 'SPB')) ? filters.isInStock : null, //Фильтр "В наличии" работает только для СПБ
        minPrice: filters?.maybePriceStart || null,
        maxPrice: filters?.maybePriceEnd || null,
        searchText: filters?.searchText || null,
        pageZeroBasedNumber: filters?.pageNumber || 0,
        pageSize: filters?.pageSize || 10
      }

      return axiosInstance.get(
        '/price',
        {
          params
        },
      )
        .then(({ data }: AxiosResponse<PaginationResult<Product[]>>) => {
          return data
        })
    } catch (error) {
      console.error(error)
    }
  },
  async catalog(): Promise<CatalogItem[]> {
    return axiosInstance.get('/catalog')
      .then(({ data }: AxiosResponse<Record<'items', CatalogItem[]>>) => {
        return data.items
      })
  },
  async groups(type: ProductType): Promise<GroupItem[]> {
    const params = {
      groupsFor: type,
    }

    return axiosInstance.get(
      '/price/groups',
      {
        params,
      },
    )
      .then(({ data }: AxiosResponse<GroupItem[]>) => {
        return data
      })
  },
  async fabrics(): Promise<string[]> {
    return axiosInstance.get(
      '/price/fabrics'
    )
      .then(({ data }: AxiosResponse<string[]>) => {
        return data
      })
  },
  async priceLimits(): Promise<PriceLimit[]> {
    return axiosInstance.get(
      '/price/pricesLimits'
    )
      .then(({ data }: AxiosResponse<PriceLimit[]>) => {
        return data
      })
  },
  async getImage(data: ImageType): Promise<ImageResponseType> {
    const params: ImageRequestType = {
      art: data.art,
      imageId: data.mainImageId
    }

    return axiosInstance.get(
      `/price/product/${params.art}/image/${params.imageId}`,
      {
        responseType: 'blob'
      }
    )
      .then(({ data }: AxiosResponse<Blob>) => {
        const src = URL.createObjectURL(data)

        return {
          id: params.imageId,
          src
        }
      })
  },
}
