import styled from 'styled-components'

import { commonStyle } from '../../../../../../../../styles'

export const StyledTableView = styled.div.attrs(() => ({
  className: 'table-view',
}))`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: visible;
  position: relative;

  .cart-info {
    position: fixed;
    top: 0.5rem;
    right: 2rem;
    z-index: ${() => commonStyle.layers.firstLayer};
  }

  .up-button-left-side {

    .up-button {
      position: fixed;
      bottom: 5rem;
      left: 8rem;
      z-index: ${() => commonStyle.layers.firstLayer};
    }
  }
  
  .up-button-right-side {

    .up-button {
      position: fixed;
      bottom: 5rem;
      right: 8rem;
      z-index: ${() => commonStyle.layers.firstLayer};
    }
  }
  
  table {
    position: relative;
    border-collapse: collapse;
    
    thead {
      position: sticky;
      z-index: ${() => commonStyle.layers.secondLayer};
      top: 0;
      height: var(--header-table-height);
      background-color: ${props => props.theme.backgroundColor};
      color: ${props => props.theme.secondColor};

      tr {
        height: 100%;
        box-shadow: 0 1px 0 0 ${() => commonStyle.colors.grey224};

        th {
          height: 100%;
          padding: 0;
          background-color: ${props => props.theme.backgroundColor};
          font-size: ${() => commonStyle.fonts.fs10};
          font-weight: ${() => commonStyle.fontStyles.fw500};

          .top-left {
            display: flex;
            justify-content: start;
            align-items: start;
          }
          
          .center-left {
            display: flex;
            justify-content: start;
            align-items: center;
            text-align: left;
          }
        }
        
        .name {
          padding-left: 0.625rem;
        }

        .min-order {
          padding-left: 1.25rem;
        }
        
        .full-price {
          padding-left: 1.25rem;
        }
        
        .sale {
          padding-left: 1.25rem;
        }
      }
    }
    
    tbody {

      tr {
        scroll-margin-top: var(--header-table-height);
        min-height: 2.25rem !important;
        border-bottom: 1px solid ${() => commonStyle.colors.grey224};

        td {
          min-height: 2.25rem !important;
        }
      }

      .tea-group-separate {
        height: 2.25rem !important;
        min-height: 2.25rem !important;
        background-color: ${() => commonStyle.colors.grey224};
        
        td {
          height: 2.25rem !important;
          min-height: 2.25rem !important;
          padding-left: 5.5rem;
          font-size: ${() => commonStyle.fonts.fs20};
          font-weight: ${() => commonStyle.fontStyles.fw600};
        }
      }
    }
  }
  
  .load-element {
    height: 5rem;
    position: relative;
    
    .loading-spinner {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(-50%, -50%);
    }
  }
  
  //Если экран шире full hd, то у нас появляются боковые полосы. Нужно смещать кнопки от краёв.
  //Поэтому завязываемся на основное разрешение 1920px (возможно, стоит вынести в переменную)
  @media screen and (min-width: 1921px) {
    .cart-info {
      right: 50%;
      transform: translateX(calc(960px - 2.8rem));
    }

    .up-button-left-side {

      .up-button {
        left: 50%;
        transform: translateX(calc(-480px - 8rem));
      }
    }

    .up-button-right-side {

      .up-button {
        right: 50%;
        transform: translateX(calc(480px));
      }
    }
  }

  @media screen and (max-width: 1920px) {
    .cart-info {
      right: 50%;
      transform: translateX(calc(720px - 2.2rem));
    }
  }

  @media screen and (max-width: 1440px) {
    .cart-info {
      right: 0;
      transform: translateX(-2.2rem);
    }
  }

  @media screen and (max-width: 1201px) {
    table {
      colgroup {
        .min-order {
          width: 3.5rem !important;
        }

        .full-price {
          width: 4rem !important;
        }
        
        .sale {
          width: 4rem !important;
        }
      }
      
      thead {
        tr {
          .min-order {
            width: 3.5rem !important;
          }
          
          .full-price {
            width: 4rem !important;
          }

          .sale {
            width: 4rem !important;
          }
        }
      }
    }
  }

  @media screen and (max-width: 961px) {
    table {
      thead {
        tr {
          .name {
            padding-left: 0.5rem;
          }

          .min-order {
            padding-left: 0.5rem;
          }

          .full-price {
            padding-left: 0.5rem;
          }

          .sale {
            padding-left: 0.5rem;
          }
        }
      }
    }
  }
`
