import styled from 'styled-components'

import { commonStyle } from '../../../../../styles'

export const StyledReset = styled.div.attrs(() => ({
  className: 'reset',
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.75rem;
  width: 400px;
  padding: 3rem 2rem;
  
  h2 {
    font-size: ${() => commonStyle.fonts.fs32};
    font-weight: ${() => commonStyle.fontStyles.fw600};
    color: ${props => props.theme.color};
  }
  
  p {
    text-align: center;
    font-size: ${() => commonStyle.fonts.fs16};
    color: ${props => props.theme.secondColor};
  }
  
  .attention {
    font-weight: bold;
  }
`
