import styled from 'styled-components'

export const StyledAuthorization = styled.div.attrs(() => ({
  className: 'authorization',
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: fit-content;
  padding: 3rem 2rem;

  @media screen and (max-width: 1439px) {
    width: 400px;
    padding: 2rem 1rem;
  }

  @media screen and (max-width: 1199px) {
    width: 340px;
    padding: 2rem 1rem;
  }

  @media screen and (max-width: 979px) {
    width: 320px;
    padding: 1.5rem 1rem;
  }

  @media screen and (max-width: 759px) {
    width: 280px;
    padding: 1rem;
  }

  @media screen and (max-width: 479px) {
    width: 260px;
    padding: 1rem;
  }
`
