import styled from 'styled-components'

import { commonStyle } from '../../../../../../../../../../../../../../styles'

export const StyledItemCardPrice = styled.div.attrs(() => ({
  className: 'item-card-price',
}))`
  display: flex;
  gap: 0.5rem;
  width: 100%;
  padding: 0.25rem 1rem;
  background-color: ${() => commonStyle.colors.grey100};;
  border-radius: ${() => commonStyle.radii.radius8};
  
  .not-sale {
    font-size: ${() => commonStyle.fonts.fs28};
    font-weight: ${() => commonStyle.fontStyles.fw600};
  }

  .sale {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .prices {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      
      .prices-container {
        display: flex;
        justify-content: space-between;
        align-items: end;
        gap: 1.5rem;
        
        .sale-price {
          font-size: ${() => commonStyle.fonts.fs28};
          font-weight: ${() => commonStyle.fontStyles.fw600};
          
          .price {
            
          }
        }

        .standard-price {

          .price {
            color: ${() => commonStyle.colors.grey400};
            font-size: ${() => commonStyle.fonts.fs14};
            text-decoration: line-through;
          }
        }
      }
      
      .percentage {
        display: flex;
        gap: 0.5rem;
        
        .text {
          font-size: ${() => commonStyle.fonts.fs14};
          color: ${() => commonStyle.colors.grey400};
        }
        
        .number {
          font-size: ${() => commonStyle.fonts.fs14};
          color: ${() => commonStyle.colors.black};
          font-weight: ${() => commonStyle.fontStyles.fw600};
          word-spacing: 2px;
        }
      }
    }
  }
`
