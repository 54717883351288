import styled from 'styled-components'

export const StyledCheckoutPage = styled.div.attrs(() => ({
  className: 'checkout-page page',
}))`
  .checkout-container {
    display: grid;
    position: relative;

    grid-template-areas:
    "back back back"
    "name name name"
    "nav nav data"
    "checkout checkout data";

    grid-template-rows: 2.5rem 2.5rem 2.5rem 1fr;
    grid-template-columns: 10rem 1fr 16.5rem;
    gap: 1rem 2rem;
  }

  @media screen and (max-width: 961px) {
    grid-template-columns: 10rem 1fr 12rem;
  }
`
