import styled from 'styled-components'

import { commonStyle } from '../../../../../../styles'

export const StyledCartData = styled.div.attrs(() => ({
  className: 'cart-data',
}))`
  grid-area: data;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 16.5rem;
  position: sticky;
  top: 0;
  align-self: start;

  h2 {
    font-size: ${() => commonStyle.fonts.fs20};
    font-weight: ${() => commonStyle.fontStyles.fw600};
  }

  .info-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;

    .info {
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;
      font-size: ${() => commonStyle.fonts.fs14};
      font-weight: ${() => commonStyle.fontStyles.fw500};

      .first {
        color: ${props => props.theme.secondColor};
        font-weight: ${() => commonStyle.fontStyles.fw600};
      }

      .second {
        font-weight: ${() => commonStyle.fontStyles.fw600};
      }
    }

    .city {
      .second {
        color: ${props => props.theme.secondColor};
      }
    }

    .weight {
      .second {
        color: ${props => props.theme.secondColor};
      }
    }

    .sales, .specials-sales, .client-sales {
      .second {
        color: ${() => commonStyle.colors.red};
      }
    }

    .price {
      .second {
        color: ${props => props.theme.color};
        font-size: ${() => commonStyle.fonts.fs20};
      }
    }
  }

  .checkout {
    width: 100%;
    height: 4rem;
    margin-top: 2rem;
    background-color: ${() => commonStyle.colors.red};
    color: ${() => commonStyle.colors.white};
    font-size: ${() => commonStyle.fonts.fs14};
    font-weight: ${() => commonStyle.fontStyles.fw600};
    font-family: 'MontserratFont', sans-serif;
    border-radius: ${() => commonStyle.radii.radius16};
    text-transform: inherit;
    box-shadow: none;
    transition: background-color ${() => commonStyle.times.time3};

    &:hover {
      background-color: ${() => commonStyle.colors.darkRed};
      transition: background-color ${() => commonStyle.times.time3};
    }
  }

  .min-order {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.25rem;
    margin-top: 1rem;
    color: ${props => props.theme.secondColor};
  }

  @media screen and (max-width: 961px) {
    width: 12rem;

    h2 {
      font-size: ${() => commonStyle.fonts.fs16};
    }

    .info-container {
      .info {
        font-size: ${() => commonStyle.fonts.fs12};
      }

      .price {
        .second {
          color: ${props => props.theme.color};
          font-size: ${() => commonStyle.fonts.fs16};
        }
      }
    }

    .checkout {
      height: 3rem;
      font-size: ${() => commonStyle.fonts.fs12};
    }

    .min-order {
      font-size: ${() => commonStyle.fonts.fs12}
    }
  }
`
