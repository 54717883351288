import styled from 'styled-components'

import { commonStyle } from '../../../../../styles'

export const StyledNavigation = styled.div.attrs(() => ({
  className: 'navigation',
}))`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0.5rem;

  nav {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 0 1rem;

    div {
      font-size: ${() => commonStyle.fonts.fs14};
      cursor: pointer;
    }

    .not-active {
      color: ${props => props.theme.secondColor};
      transition: color ${() => commonStyle.times.time3};
    }

    .active {
      color: ${props => props.theme.color};
      transition: color ${() => commonStyle.times.time3};
    }
  }

  @media screen and (max-width: 961px) {
    nav {
      width: 45%;
      padding: 0 0 0 2rem;
      div {
        font-size: ${() => commonStyle.fonts.fs12};
      }
    }
  }

  @media screen and (max-width: 761px) {
    nav {
      width: 60%;
      padding: 0 0 0 2rem;
      div {
        font-size: ${() => commonStyle.fonts.fs12};
      }
    }
  }
`
