import React from 'react'
import { useSelector } from 'react-redux'
import { IStore } from '@store/store'
import Icons from '@icons/icons'
import { ThemeProvider } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { StyledBackToCatalog } from '@components/views/components/backToCatalog/styles/backToCatalog.styled'
// @ts-ignore
import { Routers } from '@types/routers'

import { commonStyle } from '../../../../styles'


export function BackToCatalog() {
  const navigate = useNavigate()

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  const setRouter = (routerName: Routers) => {
    navigate(routerName)
  }

  const goToCatalog = () => {
    setRouter('/')
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledBackToCatalog>
        <div onClick={goToCatalog}>
          <Icons name='arrow-back' color='#828282' size='24' className='icon' />
          <p>На главную страницу</p>
        </div>
      </StyledBackToCatalog>
    </ThemeProvider>
  )
}
