import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { IStore } from '@store/store'
import { ThemeProvider } from 'styled-components'
import { StyledCheckoutNav } from '@components/views/checkoutPage/components/checkoutNav/styles/checkoutNav.styled'
// @ts-ignore
import { Routers } from '@types/routers'

import { commonStyle } from '../../../../../styles'

export function CheckoutNav() {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  const receiveInformation = useSelector((state: IStore) => state.checkout.receiveInformation)

  const navigate = useNavigate()

  const location = useLocation()
  const currentPath = location.pathname

  const setRouter = (routerName: Routers) => {
    navigate(routerName)
  }

  const isCanGoToInfo = () => {
    return !!receiveInformation.deliveryMethod
  }

  const isCanGoToConfirm = () => {
    return (
      !!receiveInformation.deliveryMethod &&
      !!receiveInformation.receiver.name &&
      !!receiveInformation.receiver.phoneNumber &&
      !!receiveInformation.address.city &&
      !!receiveInformation.address.street &&
      !!receiveInformation.address.apartmentNumber &&
      !!receiveInformation.paymentMethod
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledCheckoutNav>
        <p
          className={`${currentPath === '/checkout/delivery' ? 'active' : ''}`}
          onClick={() => setRouter('delivery')}
        >
          Способы доставки
        </p>

        <p
          className={`
            ${currentPath === '/checkout/info' ? 'active' : ''}
            ${isCanGoToInfo() ? '' : 'disabled'}
          `}
          onClick={isCanGoToInfo() ? () => setRouter('info') : () => {}}
        >
          Информация о заказе
        </p>

        <p
          className={`
            ${currentPath === '/checkout/confirm' ? 'active' : ''}
            ${isCanGoToConfirm() ? '' : 'disabled'}
          `}
          onClick={isCanGoToConfirm() ? () => setRouter('confirm') : () => {}}
        >
          Подтверждение заказа
        </p>
      </StyledCheckoutNav>
    </ThemeProvider>
  )
}
