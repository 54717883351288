import { products } from '@endpoints/endpoints/products/products'
import { auth } from '@endpoints/endpoints/auth/auth'
import { cart } from '@endpoints/endpoints/cart/cart'
import { checkout } from '@endpoints/endpoints/checkout/checkout'

export const endpoints = {
  products: products,
  auth: auth,
  cart: cart,
  checkout: checkout
}
