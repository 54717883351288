import styled from 'styled-components'

export const StyledTeaPage = styled.div.attrs(() => ({
  className: 'tea-page page',
}))`
  display: grid;
  
  grid-template-areas:
    "news news"
    "categories filters"
    "categories products";

  //grid-template-rows: 6.25rem 5.5rem 1fr; //Add for news widget
  grid-template-rows: 0 5.5rem 1fr;
  grid-template-columns: 17.5rem 1fr;
  gap: 1rem;

  height: 100%;
  width: 100%;

  @media screen and (max-width: 961px) {
    grid-template-areas:
    "news"
    "filters"
    "products";
    
    grid-template-columns: 1fr;
    
    .categories {
      display: none;
    }
  }

  /*@media screen and (max-width: 761px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }*/
`
