import styled from 'styled-components'

import { commonStyle } from '../../../../../../styles'

export const StyledCategoriesButton = styled.div.attrs(() => ({
  className: 'categories-button',
}))`
  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    height: 2.25rem;
    padding: 0.5rem;
    background-color: ${() => commonStyle.colors.white};
    font-family: 'MontserratFont', sans-serif;
    font-size: ${() => commonStyle.fonts.fs14};
    box-shadow: ${() => commonStyle.shadows.primaryShadow};
    border: none;
    border-radius: ${() => commonStyle.radii.radius8};
    cursor: pointer;
    
    div {
      display: flex;
      gap: 0.5rem;
      
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
      }
      
      .text {
        color: ${props => props.theme.color};
        font-weight: ${() => commonStyle.fontStyles.fw400};
        text-transform: capitalize;
        line-height: 24px;
      }
    }
  }
`
