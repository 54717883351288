import React from 'react'
import { useSelector } from 'react-redux'
import { IStore } from '@store/store'
import { ThemeProvider } from 'styled-components'
import { StyledCheckoutName } from '@components/views/checkoutPage/components/checkoutName/styles/checkoutName.styled'

import { commonStyle } from '../../../../../styles'

export function CheckoutName() {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledCheckoutName>
        <h2>Оформление</h2>
      </StyledCheckoutName>
    </ThemeProvider>
  )
}
