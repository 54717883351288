import styled from 'styled-components'

import { commonStyle } from '../../../../styles'

export const StyledPurchasePage = styled.div.attrs(() => ({
  className: 'purchase-page page',
}))`
  display: grid;
  position: relative;

  grid-template-areas:
    'back'
    'purchase';

  grid-template-rows: 2.5rem 1fr;
  grid-template-columns: 1fr;
  gap: 1rem 2rem;

  .purchase-container {
    grid-area: purchase;

    max-width: 37.5rem;
    padding-bottom: 3rem;

    h1 {
      font-size: ${() => commonStyle.fonts.fs32};
      font-weight: ${() => commonStyle.fontStyles.fw600};
      text-transform: uppercase;
    }
    
    .image-container {
      width: 100%;
      padding: 1rem;
      background-color: ${() => commonStyle.colors.grey100};
      border-radius: ${() => commonStyle.radii.radius16};
      
      img {
        width: 100%;
      }
    }
    
    .type-selector {
      display: flex;
      gap: 2rem;
      
      p {
        padding: 0.5rem 0;
        font-size: ${() => commonStyle.fonts.fs24};
        color: ${props => props.theme.secondColor};
        cursor: pointer;
      }
      
      .active {
        color: ${() => commonStyle.colors.red};
        border-bottom: 3px solid ${() => commonStyle.colors.red};
      }
    }
    
    .purchase {
      h2 {
        font-size: ${() => commonStyle.fonts.fs20};
        color: ${() => commonStyle.colors.red};
        font-weight: ${() => commonStyle.fontStyles.fw500};
      }

      h3 {
        font-size: ${() => commonStyle.fonts.fs16};
        font-weight: ${() => commonStyle.fontStyles.fw600};
      }
      
      a {
        color: ${() => commonStyle.colors.red};
        font-weight: ${() => commonStyle.fontStyles.fw600};
      }
      
      .min-order-position {
        display: flex;
        gap: 1rem;
        
        img {
          width: 7.5rem;
          height: 7.5rem;
        }
      }
      
      .product-position {
        .red {
          padding: 0.25rem;
          border-radius: ${() => commonStyle.radii.radius4};
          background-color: rgba(255, 221, 227, 1);
        }
        
        .green {
          padding: 0.25rem;
          border-radius: ${() => commonStyle.radii.radius4};
          background-color: rgba(177, 247, 207, 1);
        }
      }
    }
    
    .spb-purchase {
      
    }
    
    .china-purchase {
      
    }
    
    p {
      font-size: ${() => commonStyle.fonts.fs12};
      line-height: 160%;
    }
  }
`
