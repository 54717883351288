import styled from 'styled-components'

import { commonStyle } from '../../../../../../styles'

export const StyledCheckoutConfirm = styled.div.attrs(() => ({
  className: 'checkout-confirm',
}))`
  grid-area: checkout;

  display: flex;
  flex-direction: column;
  
  .confirm-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem 1rem 2rem 1rem;
    border-bottom: 2px solid ${() => commonStyle.colors.grey224};

    p {
      font-size: ${() => commonStyle.fonts.fs20};
      font-weight: ${() => commonStyle.fontStyles.fw600};
    }
  }
  
  .confirm-contact {
    .contacts-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .contact-text {
        font-size: ${() => commonStyle.fonts.fs14};
        font-weight: ${() => commonStyle.fontStyles.fw500};
      }
    }
  }
  
  .confirm-delivery {
    .delivery-row {
      display: flex;
      gap: 0.5rem;

      p {
        font-size: ${() => commonStyle.fonts.fs16};
        font-weight: ${() => commonStyle.fontStyles.fw500};
      }
    }
    
    .address-row {
      .address-text {
        font-size: ${() => commonStyle.fonts.fs14};
        font-weight: ${() => commonStyle.fontStyles.fw500};
      }
    }
  }
  
  .confirm-payment {
    .payment-row {
      .payment-text {
        font-size: ${() => commonStyle.fonts.fs14};
        font-weight: ${() => commonStyle.fontStyles.fw500};
      }
    }
  }
`
