import styled from 'styled-components'

import { commonStyle } from '../../../../../../styles'

export const StyledCheckoutName = styled.div.attrs(() => ({
  className: 'checkout-name',
}))`
  grid-area: name;
  
  h2 {
    font-size: ${() => commonStyle.fonts.fs28};
    font-weight: ${() => commonStyle.fontStyles.fw600};
    color: ${props => props.theme.color};
    text-transform: uppercase;
  }
`
