import styled from 'styled-components'

import { commonStyle } from '../../../../../../styles'

export const StyledCheckoutInfo = styled.div.attrs(() => ({
  className: 'checkout-info',
}))`
  grid-area: checkout;

  display: flex;
  flex-direction: column;

  padding: 1rem;

  .info-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem 0;
    border-bottom: 2px solid ${() => commonStyle.colors.grey224};

    p {
      font-size: ${() => commonStyle.fonts.fs20};
      font-weight: ${() => commonStyle.fontStyles.fw600};
    }
  }
  
  .delivery-info {
    padding: 0 0 1rem 0;
    .delivery-cart {
      display: flex;
      gap: 0.5rem;

      p {
        font-size: ${() => commonStyle.fonts.fs16};
        font-weight: ${() => commonStyle.fontStyles.fw500};
      }
    }
  }
  
  .contacts-info {
    
    .contacts-field {
      .input-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .input-info {
          width: 12.5rem;
          border: 1px solid ${() => commonStyle.colors.grey500};
          border-radius: ${() => commonStyle.radii.radius8} !important;

          .MuiInputBase-root {

            .MuiInputBase-input {
              font-size: ${() => commonStyle.fonts.fs14};
            }
          }

          div {
            height: 2.5rem;
            font-family: 'MontserratFont', sans-serif;

            .MuiOutlinedInput-notchedOutline {
              border: none;
            }
          }
        }
        
        .name {
          width: 25rem;
        }
        
        .phoneNumber {
          .MuiInputBase-root {
            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }

        .required {
          border: 1px solid ${() => commonStyle.colors.red};
        }
      }
    }
  }
  
  .address-info {
    gap: 0;
    border-bottom: none;
    
    .address {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      border-bottom: 2px solid ${() => commonStyle.colors.grey224};
      
      p {
        font-size: ${() => commonStyle.fonts.fs20};
        font-weight: ${() => commonStyle.fontStyles.fw600};
      }
      
      .address-field {
        padding-bottom: 1rem;
        
        .input-container {
          display: flex;
          gap: 1rem;

          .input-info {
            width: 12.5rem;
            border: 1px solid ${() => commonStyle.colors.grey500};
            border-radius: ${() => commonStyle.radii.radius8} !important;

            .MuiInputBase-root {

              .MuiInputBase-input {
                font-size: ${() => commonStyle.fonts.fs14};
              }
            }

            div {
              height: 2.5rem;
              font-family: 'MontserratFont', sans-serif;

              .MuiOutlinedInput-notchedOutline {
                border: none;
              }
            }
          }
          
          .required {
            border: 1px solid ${() => commonStyle.colors.red};
          }

          .input-info-small {
            width: 6.25rem;
          }
          
          .input-info-big {
            width: 26rem;
          }
          
          .small-comment {
            width: 20.7rem !important;
          }
        }
        
        .warehouse-text {
          line-height: 24px;
          font-size: ${() => commonStyle.fonts.fs14};
          font-weight: ${() => commonStyle.fontStyles.fw500};
        }
      }
    }
    
    .sdek-delivery, .courier {
      .address-field {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
  
  .payments-info {
    border-bottom: none;
    
    .payment-fields {
      .MuiFormGroup-root {
        .MuiFormControlLabel-root {
          .Mui-checked {
            color: ${() => commonStyle.colors.red};
          }
        }
      }
    }
  }
  
  .next-button {
    width: 10rem;
    height: 3rem;
    background-color: ${() => commonStyle.colors.red};
    color: ${() => commonStyle.colors.white};
    font-size: ${() => commonStyle.fonts.fs14};
    font-weight: ${() => commonStyle.fontStyles.fw600};
    font-family: 'MontserratFont', sans-serif;
    border-radius: ${() => commonStyle.radii.radius8};
    text-transform: inherit;
    box-shadow: none;
    transition: background-color ${() => commonStyle.times.time3};

    &:hover {
      background-color: ${() => commonStyle.colors.darkRed};
      transition: background-color ${() => commonStyle.times.time3};
    }
  }
`
