import styled from 'styled-components'

import { commonStyle } from '../../../../styles'

export const StyledPricePage = styled.div.attrs(() => ({
  className: 'price-page page',
}))`
  display: grid;
  position: relative;

  grid-template-areas:
    "back"
    "price";

  grid-template-rows: 2.5rem 1fr;
  grid-template-columns: 1fr;
  gap: 1rem 2rem;

  .price-container {
    grid-area: price;

    h1 {
      font-size: ${() => commonStyle.fonts.fs32};
      font-weight: ${() => commonStyle.fontStyles.fw600};
      text-transform: uppercase;
    }

    p {
      font-size: ${() => commonStyle.fonts.fs12};
      line-height: 160%;
    }
    
    .prices {
      display: flex;
      gap: 2rem;
      
      .price {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        width: 138px;
        
        .card {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 138px;
          height: 150px;
          border-radius: ${() => commonStyle.radii.radius8};
          box-shadow: ${() => commonStyle.shadows.primaryShadow};
          cursor: pointer;
          transition: box-shadow ${() => commonStyle.times.time3};
          
          img {
            width: 37px;
            height: 46px;
          }
          
          &:hover {
            box-shadow: ${() => commonStyle.shadows.secondaryShadow};
            transition: box-shadow ${() => commonStyle.times.time3};
          }
        }
        
        .text {
          font-size: ${() => commonStyle.fonts.fs10};
          color: ${props => props.theme.secondColor};
        }
      }
    }
  }
`
