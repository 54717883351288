import React from 'react'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'
import { Button, Menu } from '@mui/material'
import { IStore } from '@store/store'
import Icons from '@icons/icons'
import { Categories } from '@components/views/teaPage/components/categories/categories'
import { StyledCategoriesButton } from '@components/views/teaPage/components/categories/styles/categoriesButton.styled'

import { commonStyle } from '../../../../../styles'

export function CategoriesButton() {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledCategoriesButton>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <div>
            <Icons name='menu' color='#B70A06' size="24" className="icon" />

            <p className="text">Категории</p>

            {open ? (<Icons name="arrow-up-grey" color="#fff" size="20" className="icon" />)
              : ((<Icons name="arrow-down-grey" color="#fff" size="20" className="icon" />)
            )}
          </div>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Categories />
        </Menu>
      </StyledCategoriesButton>
    </ThemeProvider>
  )
}
