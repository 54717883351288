import styled from 'styled-components'

import { commonStyle } from '../../../../../../styles'

export const StyledCheckoutNav = styled.div.attrs(() => ({
  className: 'checkout-nav',
}))`
  grid-area: nav;
  display: flex;
  gap: 5rem;
  width: fit-content;
  height: 40px;
  border-bottom: 2px solid ${() => commonStyle.colors.grey600};
  
  p {
    height: 40px;
    font-size: ${() => commonStyle.fonts.fs20};
    color: ${() => commonStyle.colors.grey600};
    cursor: pointer;
    border-bottom: 2px solid ${() => commonStyle.colors.grey600};
  }
  
  .active {
    color: ${props => props.theme.color};
    border-bottom: 2px solid ${props => props.theme.color};
    font-weight: ${() => commonStyle.fontStyles.fw600};
  }
  
  .disabled {
    cursor: not-allowed;
  }
`
