import styled from 'styled-components'

export const StyledTableCard = styled.div.attrs(() => ({
  className: 'table-card',
}))`
  display: flex;
  gap: 3.125rem;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 2rem 0;
  
  .item-info {
    flex-grow: 1;
  }
`
