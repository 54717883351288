import React from 'react'
import { useSelector } from 'react-redux'
import { Box, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import Icons from '@icons/icons'
import { getDeliveryIconNameFromMethod, getDeliveryNameFromMethod, isRequiredCheckoutField } from '@components/views/checkoutPage/utils/common'
import { IStore, useAppDispatch } from '@store/store'
import { updateField } from '@store/checkout'
import { ThemeProvider } from 'styled-components'
import { StyledCheckoutInfo } from '@components/views/checkoutPage/components/checkoutInfo/styles/checkoutInfo.styled'

import { commonStyle } from '../../../../../styles'

export function CheckoutInfo() {
  const dispatch = useAppDispatch()

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  const deliveryMethod = useSelector((state: IStore) => state.checkout.receiveInformation.deliveryMethod)
  const receiveInformation = useSelector((state: IStore) => state.checkout.receiveInformation)

  const paymentMethods = [
    { value: 'BY_BILL', label: 'по счёту (для ИП и юр. лиц)' },
    { value: 'BY_QR', label: 'по QR (для физ. лиц)' },
    { value: 'BY_CASH', label: 'наличными (при самовывозе)' },
  ]

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    dispatch(updateField({ fieldPath: name, value }))
  }

  // @ts-ignore
  return (
    <ThemeProvider theme={theme}>
      <StyledCheckoutInfo>
        <div className="info-container delivery-info">
          <p>Способ доставки</p>

          <div className="delivery-cart">
            <Icons name={getDeliveryIconNameFromMethod(deliveryMethod)} color="#fff" size="20" className="icon" />
            <p>{getDeliveryNameFromMethod(deliveryMethod)}</p>
          </div>
        </div>

        <div className="info-container contacts-info">
          <p>Контактная информация</p>

          <div className="contacts-field">
            <Box className="input-container">
              <TextField
                id='name'
                name='receiveInformation.receiver.name'
                className={`
                  input-info
                  name
                  ${isRequiredCheckoutField('receiveInformation.receiver.name') ? 'required' : ''}
                `}
                value={receiveInformation.receiver.name}
                placeholder='Имя'
                color='primary'
                onChange={handleInputChange}
              />

              <TextField
                id="phoneNumber"
                name='receiveInformation.receiver.phoneNumber'
                className={`
                  input-info
                  phoneNumber
                  ${isRequiredCheckoutField('receiveInformation.receiver.phoneNumber') ? 'required' : ''}
                `}
                value={receiveInformation.receiver.phoneNumber}
                placeholder="Телефон"
                color="primary"
                onChange={handleInputChange}
              />
            </Box>
          </div>
        </div>

        <div className="info-container address-info">
          {deliveryMethod === 'CDEK' && <div className="address sdec-department">
            <p>Введите адрес отделения СДЭК</p>

            <div className="address-field">
              <Box className="input-container">
                <TextField
                  id="city"
                  name='receiveInformation.address.city'
                  className={`
                    input-info
                    city
                    ${isRequiredCheckoutField('receiveInformation.address.city') ? 'required' : ''}
                  `}
                  value={receiveInformation.address.city}
                  placeholder="город"
                  color="primary"
                  onChange={handleInputChange}
                />

                <TextField
                  id="street"
                  name='receiveInformation.address.street'
                  className={`
                    input-info
                    street
                    ${isRequiredCheckoutField('receiveInformation.address.street') ? 'required' : ''}
                  `}
                  value={receiveInformation.address.street}
                  placeholder="улица"
                  color="primary"
                  onChange={handleInputChange}
                />

                <TextField
                  id="buildingNumber"
                  name='receiveInformation.address.apartmentNumber'
                  className={`
                    input-info
                    input-info-small
                    apartmentNumber
                    ${isRequiredCheckoutField('receiveInformation.address.apartmentNumber') ? 'required' : ''}
                  `}
                  value={receiveInformation.address.apartmentNumber}
                  placeholder="дом"
                  color="primary"
                  onChange={handleInputChange}
                />

                <TextField
                  id="apartmentNumber"
                  name='receiveInformation.address.buildingNumber'
                  className={`
                    input-info
                    input-info-small
                    buildingNumber
                    ${isRequiredCheckoutField('receiveInformation.address.buildingNumber') ? 'required' : ''}
                  `}
                  value={receiveInformation.address.buildingNumber}
                  placeholder="корпус"
                  color="primary"
                  onChange={handleInputChange}
                />
              </Box>
            </div>
          </div>}

          {['CDEK_DOOR', 'COURIER', 'ZHELDOR_EKSPEDITSIYA', 'PEC'].includes(deliveryMethod) && <div className="address sdek-delivery courier zhde-delivery pek-delivery">
            <p>Адрес доставки</p>

            <div className="address-field">
              <Box className="input-container">
                <TextField
                  id="city"
                  name='receiveInformation.address.city'
                  className={`
                    input-info
                    city
                    ${isRequiredCheckoutField('receiveInformation.address.city') ? 'required' : ''}
                  `}
                  value={receiveInformation.address.city}
                  placeholder="город"
                  color="primary"
                  onChange={handleInputChange}
                />

                <TextField
                  id="street"
                  name='receiveInformation.address.street'
                  className={`
                    input-info
                    street
                    ${isRequiredCheckoutField('receiveInformation.address.street') ? 'required' : ''}
                  `}
                  value={receiveInformation.address.street}
                  placeholder="улица"
                  color="primary"
                  onChange={handleInputChange}
                />

                <TextField
                  id="buildingNumber"
                  name='receiveInformation.address.apartmentNumber'
                  className={`
                    input-info
                    input-info-small
                    apartmentNumber
                    ${isRequiredCheckoutField('receiveInformation.address.apartmentNumber') ? 'required' : ''}
                  `}
                  value={receiveInformation.address.apartmentNumber}
                  placeholder="дом"
                  color="primary"
                  onChange={handleInputChange}
                />

                <TextField
                  id="apartmentNumber"
                  name='receiveInformation.address.buildingNumber'
                  className={`
                    input-info
                    input-info-small
                    buildingNumber
                    ${isRequiredCheckoutField('receiveInformation.address.buildingNumber') ? 'required' : ''}
                  `}
                  value={receiveInformation.address.buildingNumber}
                  placeholder="корпус"
                  color="primary"
                  onChange={handleInputChange}
                />

                <TextField
                  id="flatNumber"
                  name='receiveInformation.address.flatNumber'
                  className={`
                    input-info
                    input-info-small
                    flatNumber
                    ${isRequiredCheckoutField('receiveInformation.address.flatNumber') ? 'required' : ''}
                  `}
                  value={receiveInformation.address.flatNumber}
                  placeholder="кв/офис"
                  color="primary"
                  onChange={handleInputChange}
                />
              </Box>

              <Box className="input-container">
                {['ZHELDOR_EKSPEDITSIYA', 'PEC'].includes(deliveryMethod) && <TextField
                  id="deliveryId"
                  name='receiveInformation.address.deliveryId'
                  className={`
                    input-info
                    input-info-big
                    deliveryId
                    ${isRequiredCheckoutField('receiveInformation.address.deliveryId') ? 'required' : ''}
                  `}
                  value={receiveInformation.address.deliveryId}
                  placeholder="ID (если имеется)"
                  color="primary"
                  onChange={handleInputChange}
                />}

                <TextField
                  id="comment"
                  name='receiveInformation.address.comment'
                  className={`
                    input-info
                    input-info-big
                    comment
                    ${['ZHELDOR_EKSPEDITSIYA', 'PEC'].includes(deliveryMethod) && 'small-comment'}
                    ${isRequiredCheckoutField('receiveInformation.address.comment') ? 'required' : ''}
                  `}
                  value={receiveInformation.address.comment}
                  placeholder="комментарий"
                  color="primary"
                  onChange={handleInputChange}
                />
              </Box>
            </div>
          </div>}

          {deliveryMethod === 'PICKUP' && <div className="address warehouse">
            <p>Адрес склада</p>

            <div className="address-field">
              <p className="warehouse-text">Адрес: г. Санкт-Петербург, ул. Парашютная 54</p>
              <p className="warehouse-text">Выдача заказов 14:00 - 19:00</p>
            </div>
          </div>}
        </div>

        <div className="info-container payments-info">
          <p>Выберите способ оплаты</p>

          <div className="payment-fields">
            <RadioGroup
              aria-labelledby="payment-method-label"
              name="paymentMethod"
              onChange={handleInputChange}
              value={receiveInformation.paymentMethod}
            >
              {paymentMethods.map((method) => (
                <FormControlLabel
                  name='receiveInformation.paymentMethod'
                  key={method.value}
                  value={method.value}
                  control={<Radio />}
                  label={method.label}
                />
              ))}
            </RadioGroup>
          </div>
        </div>
      </StyledCheckoutInfo>
    </ThemeProvider>
  )
}
