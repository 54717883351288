import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { IStore, useAppDispatch } from '@store/store'
import { TSelector } from '@components/views/teaPage/components/filters/components/tSelector'
import { ItemSelectorProps } from '@components/views/teaPage/components/products/components/tableView/components/tableItem/components/itemSelector/types/types'
import { ReverseUnitTypeName, reverseUnitTypeName, UnitTypeName } from '@endpoints/endpoints/products/types'
import { StyledItemSelector } from '@components/views/teaPage/components/products/components/tableView/components/tableItem/components/itemSelector/styles/itemSelector.styled'
import { setItemUnitThunk } from '@store/shoppingСart'

import { commonStyle } from '../../../../../../../../../../../styles'

export const ItemSelector = React.memo(function ItemSelector(props: ItemSelectorProps) {
  const dispatch = useAppDispatch()

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)
  const currentProductUnit = useSelector((state: IStore) => state.cart.items[props.itemId]?.unit)

  const theme = useMemo(() => ({
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }), [colorTheme])

  const units = useMemo(() => {
    return props.product.units.map(item => ({
      value: item.name,
      name: item.name,
      boxSize: item.type === 'BOX' ? props.product.valueInBox : null,
    }))
  }, [props.product.units, props.product.valueInBox])

  const handleChange = useCallback((filterName: string, value: string) => {
    const unitKey = reverseUnitTypeName[value as keyof ReverseUnitTypeName]
    dispatch(setItemUnitThunk({ id: props.itemId, unit: unitKey }))
  }, [dispatch, props.itemId])

  const unit = currentProductUnit ? UnitTypeName[currentProductUnit] : units[0].name

  return (
    <ThemeProvider theme={theme}>
      <StyledItemSelector>
        <TSelector
          filterName='unit'
          iconName=''
          options={units}
          initialValue={unit}
          onChange={handleChange}
          isDisabled={units.length <= 1 || props.isDisabled}
        />
      </StyledItemSelector>
    </ThemeProvider>
  )
})
