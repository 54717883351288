import React from 'react'
import { useSelector } from 'react-redux'
import { IStore } from '@store/store'
import { ThemeProvider } from 'styled-components'
import { StyledCheckoutConfirm } from '@components/views/checkoutPage/components/checkoutConfirm/styles/checkoutConfirm.styled'

import { commonStyle } from '../../../../../styles'
import Icons from '@icons/icons'
import {
  getAddressString,
  getDeliveryIconNameFromMethod,
  getDeliveryNameFromMethod, getPaymentMethodName,
} from '@components/views/checkoutPage/utils/common'

export function CheckoutConfirm() {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  const deliveryMethod = useSelector((state: IStore) => state.checkout.receiveInformation.deliveryMethod)
  const receiveInformation = useSelector((state: IStore) => state.checkout.receiveInformation)
  const productType = useSelector((state: IStore) => state.checkout.productType)

  return (
    <ThemeProvider theme={theme}>
      <StyledCheckoutConfirm>
        <div className="confirm-container confirm-contact">
          <p>Контактная информация</p>

          <div className="contacts-container">
            <p className='contact-text'>{receiveInformation.receiver.name}</p>
            <p className='contact-text'>{receiveInformation.receiver.phoneNumber}</p>
          </div>
        </div>

        <div className="confirm-container confirm-delivery">
          <p>Способ доставки</p>

          <div className="delivery-row">
            <Icons name={getDeliveryIconNameFromMethod(deliveryMethod)} color="#fff" size="24" className="icon" />
            <p>{getDeliveryNameFromMethod(deliveryMethod)}</p>
          </div>

          <div className="address-row">
            <p className="address-text">{getAddressString(receiveInformation, productType)}</p>
          </div>
        </div>

        <div className="confirm-container confirm-payment">
          <p>Оплата</p>

          <div className="payment-row">
            <p className="payment-text">{getPaymentMethodName(receiveInformation.paymentMethod)}</p>
          </div>
        </div>
      </StyledCheckoutConfirm>
    </ThemeProvider>
  )
}
