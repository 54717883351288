import styled from 'styled-components'

import { commonStyle } from '../../../../../../../../../../../../styles'

export const StyledItemMinOrder = styled.div.attrs(() => ({
  className: 'item-min-order',
}))`
  width: 100%;
  font-size: ${() => commonStyle.fonts.fs12};

  @media screen and (max-width: 1201px) {
    width: 3.5rem !important;
  }
`
