import styled from 'styled-components'

export const StyledLicenseAgreement = styled.div.attrs(() => ({
  className: 'license-agreement',
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 45rem;
  padding: 3rem 2rem;
  overflow-y: scroll;

  @media screen and (max-width: 1439px) {
    height: 40rem;
  }

  @media screen and (max-width: 1199px) {
    height: 40rem;
  }

  @media screen and (max-width: 979px) {
    height: 35rem;
  }

  @media screen and (max-width: 759px) {
    height: 30rem;
  }

  @media screen and (max-width: 479px) {
    height: 30rem;
  }
`
