// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/MontserratFont.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-family: 'MontserratFont', sans-serif !important;
}

:root {
  /*Переменные для основного экрана*/
  --header-height: 6.25rem;
  --padding-size: 2rem;
  --main-height: calc(100vh - var(--header-height) - (var(--padding-size) * 2));
  --scroll-bar-width: 10px;
  --max-screen-width: 1920px;

  /*Переменные для таблицы товаров*/
  --header-table-height: 2.5rem;
}

@font-face {
  font-family: 'MontserratFont';
  src: local('MontserratFont'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,oDAAoD;AACtD;;AAEA;EACE,kCAAkC;EAClC,wBAAwB;EACxB,oBAAoB;EACpB,6EAA6E;EAC7E,wBAAwB;EACxB,0BAA0B;;EAE1B,iCAAiC;EACjC,6BAA6B;AAC/B;;AAEA;EACE,6BAA6B;EAC7B,wFAAuF;AACzF","sourcesContent":["body {\r\n  margin: 0;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\nhtml {\r\n  font-family: 'MontserratFont', sans-serif !important;\r\n}\r\n\r\n:root {\r\n  /*Переменные для основного экрана*/\r\n  --header-height: 6.25rem;\r\n  --padding-size: 2rem;\r\n  --main-height: calc(100vh - var(--header-height) - (var(--padding-size) * 2));\r\n  --scroll-bar-width: 10px;\r\n  --max-screen-width: 1920px;\r\n\r\n  /*Переменные для таблицы товаров*/\r\n  --header-table-height: 2.5rem;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'MontserratFont';\r\n  src: local('MontserratFont'), url(./assets/fonts/MontserratFont.ttf) format('truetype');\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
