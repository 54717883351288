import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { StyledNavigation } from '@components/header/components/navigation/styles/navigation.styled'
import { useSelector } from 'react-redux'
import { IStore } from '@store/store'
// @ts-ignore
import { Routers } from '@types/routers'

import { commonStyle } from '../../../../styles'

export function Navigation() {
  const navigate = useNavigate()
  const currentPath = useLocation().pathname

  const setRouter = (routerName: Routers) => {
    navigate(routerName)
  }

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledNavigation>
        <div className="logo"></div>
        <nav>
          <div className={currentPath === '/about' ? 'active' : 'not-active'} onClick={() => setRouter('about')}>О нас</div>
          <div className={currentPath === '/price' ? 'active' : 'not-active'} onClick={() => setRouter('price')}>Скачать прайс</div>
          <div className={currentPath === '/purchase' ? 'active' : 'not-active'} onClick={() => setRouter('purchase')}>Как купить</div>
          <div className={currentPath === '/delivery' ? 'active' : 'not-active'} onClick={() => setRouter('delivery')}>Доставка</div>
          <div className={currentPath === '/payment' ? 'active' : 'not-active'} onClick={() => setRouter('payment')}>Оплата</div>
          <div className={currentPath === '/contacts' ? 'active' : 'not-active'} onClick={() => setRouter('contacts')}>Контакты</div>
        </nav>
      </StyledNavigation>
    </ThemeProvider>
  )
}
