import styled from 'styled-components'

export const StyledMenu = styled.div.attrs(() => ({
  className: 'menu',
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  height: 100%;
  width: 100%;
  padding: 0.5rem;

  .menu-logo {
    flex-basis: 30%;
    min-width: 30%;;
  }

  .menu-search {
    flex-grow: 1;
    max-width: 40%;
  }

  .menu-buttons {
    flex-basis: fit-content;
    min-width: fit-content;
  }
`
