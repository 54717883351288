import styled from 'styled-components'

import { commonStyle } from '../../../../../styles'

export const StyledReset = styled.div.attrs(() => ({
  className: 'reset',
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.75rem;
  width: 400px;
  padding: 3rem 2rem;
  
  h2 {
    font-size: ${() => commonStyle.fonts.fs32};
    font-weight: ${() => commonStyle.fontStyles.fw600};
    color: ${props => props.theme.color};
  }
  
  p {
    text-align: center;
    font-size: ${() => commonStyle.fonts.fs16};
    color: ${props => props.theme.secondColor};
  }
  
  .attention {
    font-weight: bold;
  }
  
  .confirm-button {
    width: 100%;
    height: 4rem;
    color: ${() => commonStyle.colors.white};
    background-color: ${() => commonStyle.colors.red};
    font-size: ${() => commonStyle.fonts.fs20};
    font-family: 'MontserratFont', sans-serif;
    border-radius: ${() => commonStyle.radii.radius16};
    text-transform: inherit;
    box-shadow: none;

    &:hover {
      background-color: ${() => commonStyle.colors.darkRed};
    }
  }

  @media screen and (max-width: 1439px) {
    width: 400px;
    padding: 2rem 1rem;

    h2 {
      font-size: ${() => commonStyle.fonts.fs28};
    }
    
    p {
      font-size: ${() => commonStyle.fonts.fs14};
    }

    .confirm-button {
      height: 3rem;
      font-size: ${() => commonStyle.fonts.fs14};
      border-radius: ${() => commonStyle.radii.radius16};
    }
  }

  @media screen and (max-width: 1199px) {
    width: 340px;
    padding: 2rem 1rem;

    h2 {
      font-size: ${() => commonStyle.fonts.fs24};
    }

    p {
      font-size: ${() => commonStyle.fonts.fs14};
    }

    .confirm-button {
      height: 2.5rem;
      font-size: ${() => commonStyle.fonts.fs14};
      border-radius: ${() => commonStyle.radii.radius16};
    }
  }

  @media screen and (max-width: 979px) {
    width: 320px;
    padding: 1.5rem 1rem;

    h2 {
      font-size: ${() => commonStyle.fonts.fs22};
    }

    p {
      font-size: ${() => commonStyle.fonts.fs12};
    }

    .confirm-button {
      height: 2.5rem;
      font-size: ${() => commonStyle.fonts.fs12};
      border-radius: ${() => commonStyle.radii.radius8};
    }
  }

  @media screen and (max-width: 759px) {
    width: 280px;
    padding: 1rem;

    h2 {
      font-size: ${() => commonStyle.fonts.fs20};
    }

    p {
      font-size: ${() => commonStyle.fonts.fs12};
    }

    .confirm-button {
      height: 2.5rem;
      font-size: ${() => commonStyle.fonts.fs12};
      border-radius: ${() => commonStyle.radii.radius8};
    }
  }

  @media screen and (max-width: 479px) {
    width: 260px;
    padding: 1rem;

    h2 {
      font-size: ${() => commonStyle.fonts.fs18};
    }

    p {
      font-size: ${() => commonStyle.fonts.fs12};
    }

    .confirm-button {
      height: 2rem;
      font-size: ${() => commonStyle.fonts.fs12};
      border-radius: ${() => commonStyle.radii.radius8};
    }
  }
`
