import styled from 'styled-components'

import { commonStyle } from '../../../../styles'

export const StyledPaymentPage = styled.div.attrs(() => ({
  className: 'payment-page page',
}))`
  display: grid;
  position: relative;

  grid-template-areas:
    'back'
    'payment';

  grid-template-rows: 2.5rem 1fr;
  grid-template-columns: 1fr;
  gap: 1rem 2rem;

  .payment-container {
    grid-area: payment;

    max-width: 37.5rem;

    h1 {
      font-size: ${() => commonStyle.fonts.fs32};
      font-weight: ${() => commonStyle.fontStyles.fw600};
      text-transform: uppercase;
    }

    h2 {
      font-size: ${() => commonStyle.fonts.fs20};
      color: ${() => commonStyle.colors.red};
      font-weight: ${() => commonStyle.fontStyles.fw600};
    }
    
    ul {
      list-style: disc;
      margin-left: 1rem;
      display: inline-block;
      
      li {
        margin-top: 1rem;
      }

      li:first-child {
        margin-top: 0;
      }
    }
    
    p {
      font-size: ${() => commonStyle.fonts.fs12};
      line-height: 160%;
    }
  }
`
