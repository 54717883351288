import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { ThemeProvider } from 'styled-components'
import { useForm } from 'react-hook-form'
import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material'
import { IStore, useAppDispatch } from '@store/store'
import { loginThunk, setAuthViewThunk, resetPassword } from '@store/auth'
import { StyledLogin } from '@components/authorization/components/login/styles/login.styled'
import { AuthError } from '@components/authorization/components/authError/authError'

import { commonStyle } from '../../../../styles'

interface IFormInput {
  userName: string
  password: string
}

export function Login() {
  const dispatch = useAppDispatch()
  const { register, handleSubmit, formState: { errors }, getValues } = useForm<IFormInput>()
  const [showPassword, setShowPassword] = useState(false)
  const [isResetPasswordLoading, setPasswordLoadingStatus] = useState(false)
  const [isShowResetPassword, setShowResetPasswordStatus] = useState(false)

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)
  const loginError = useSelector((state: IStore) => state.auth.loginServerError)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const onSubmit = async (data: IFormInput) => {
    const user: IFormInput = {
      userName: data.userName,
      password: data.password
    }

    dispatch(loginThunk(user))
  }

  const handleChangeAuthView = () => {
    dispatch(setAuthViewThunk('registration'))
  }

  // Проверяю заполнено ли поля почты, чтобы можно было показать кнопку сброса пароля
  const handleChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if(value) {
      setShowResetPasswordStatus(true)
    } else {
      setShowResetPasswordStatus(false)
    }
  }

  const reset = async () => {
    try {
      setPasswordLoadingStatus(true)
      const data = getValues()
      await dispatch(resetPassword(data.userName))
    } catch (error) {
      console.error(error)
    } finally {
      setPasswordLoadingStatus(false)
    }
  }

  // @ts-ignore
  return (
    <ThemeProvider theme={theme}>
      <StyledLogin>
        <h2>Войти</h2>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className='inputs-container'>
            <Box className='input-container'>
              <TextField
                inputProps={{
                  autocomplete: 'userName',
                  form: {
                    autocomplete: 'off',
                  }
                }}
                InputProps={{ disableUnderline: true }}
                className={`input-user-name input ${errors.userName && 'input-error'}`}
                type='text'
                placeholder='Email'
                color='primary'
                {...register('userName', { required: true })}
                onChange={handleChangeUserName}
              />
            </Box>

            <Box className="input-container">
              <TextField
                className={`input-password input ${errors.password && 'input-error'}`}
                type={showPassword ? 'text' : 'password'}
                placeholder='Пароль'
                color='primary'
                autoComplete='off'
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>,
                }}
                {...register('password', { required: true })}
              />
            </Box>

            {loginError && <AuthError field='login'/>}

            <div className='reset-password'>
              {isResetPasswordLoading && <CircularProgress className="loading-spinner" color="inherit" />}
              {isShowResetPassword && <button type='button' onClick={reset} disabled={isResetPasswordLoading}>Сбросить пароль?</button>}
            </div>
          </div>

          <Button className='enter-button' variant='contained' type='submit'>
            Войти
          </Button>
        </form>

        <div className='not-account'>
          <p>Нет аккаунта? <span onClick={() => handleChangeAuthView()} className='action-text'>Создать</span></p>
        </div>
      </StyledLogin>
    </ThemeProvider>
  )
}
