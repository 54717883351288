import React from 'react'
import { useSelector } from 'react-redux'
import { IStore } from '@store/store'
import { ThemeProvider } from 'styled-components'
import { StyledCartName } from '@components/views/cartPage/components/cartName/styles/cartName.styled'

import { commonStyle } from '../../../../../styles'

export function CartName() {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledCartName>
        <h2>Корзина</h2>
      </StyledCartName>
    </ThemeProvider>
  )
}
