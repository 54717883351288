import styled from 'styled-components'

import { commonStyle } from '../../../../../../styles'

export const StyledCartBackToCatalog = styled.div.attrs(() => ({
  className: 'back-to-catalog',
}))`
  grid-area: back;
  display: flex;
  align-items: center;
  
  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    
    .icon {
      transform: rotate(180deg);
      cursor: pointer;
    }
    
    p {
      font-size: ${() => commonStyle.fonts.fs12};
      color: ${props => props.theme.color};
      cursor: pointer;
    }
  }
`
