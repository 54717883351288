import React from 'react'
import { useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { IStore } from '@store/store'
import { getChinaSale } from '@components/views/teaPage/components/products/components/tableView/utils/common'
import { currencyToCurrency } from '@utils/common'
import { ItemPriceProps } from '@components/views/teaPage/components/products/components/tableView/components/tableItem/components/itemPrice/types/types'
import { StyledItemSale } from '@components/views/teaPage/components/products/components/tableView/components/tableItem/components/itemSale/styles/itemSale.styled'
import { Product, ProductType, UnitTypeSelector } from '@endpoints/endpoints/products/types'

import { commonStyle } from '../../../../../../../../../../../styles'

export function ItemSale(props: ItemPriceProps) {
  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)
  const productType = useSelector((state: IStore) => state.products.filters.productType)
  const currentProductNumber = useSelector((state: IStore) => state.cart.items[props.itemId]?.number)
  const currentUnit = useSelector((state: IStore) => state.cart.items[props.itemId]?.unit)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor,
  }

  const getSale = (type: ProductType, productNumber: number | undefined, product: Product, unit: UnitTypeSelector | undefined) => {
    if (type === 'CHINA' && unit) {
      return getChinaSale(productNumber, product, unit)
    }

    if (type === 'SPB' && unit) {
      if (!productNumber) return 0

      const priceForUnit = product.units.find(unitItem => unitItem.type === unit)?.priceForUnit ?? product.price

      const hasDiscountPrice = Boolean(product.spbDiscountPrice)
      const priceWithSaleForUnit = hasDiscountPrice ? Number(product.spbDiscountPrice) : priceForUnit

      let discountPerUnit = 0

      // Скидка за скидочную позицию
      if (hasDiscountPrice) {
        discountPerUnit += priceForUnit - priceWithSaleForUnit
      }

      return discountPerUnit * productNumber
    }

    return null
  }

  const sale = getSale(productType, currentProductNumber, props.product, currentUnit)

  return (
    <ThemeProvider theme={theme}>
      <StyledItemSale>
        {sale ? sale.toFixed(0) : ''}
        {sale ? currencyToCurrency(props.product.currency) : ''}
      </StyledItemSale>
    </ThemeProvider>
  )
}
