import styled from 'styled-components'

import { commonStyle } from '../../../../styles'

export const StyledAboutPage = styled.div.attrs(() => ({
  className: 'about-page page',
}))`
  display: grid;
  position: relative;

  grid-template-areas:
    'back'
    'about';

  grid-template-rows: 2.5rem 1fr;
  grid-template-columns: 1fr;
  gap: 1rem 2rem;
  
  .about-container {
    grid-area: about;
    
    max-width: 37.5rem;
    padding-bottom: 3rem;
    
    h1 {
      font-size: ${() => commonStyle.fonts.fs32};
      font-weight: ${() => commonStyle.fontStyles.fw600};
      text-transform: uppercase;
    }

    p {
      font-size: ${() => commonStyle.fonts.fs12};
      line-height: 160%;

      a {
        color: ${() => commonStyle.colors.red};
        font-weight: ${() => commonStyle.fontStyles.fw600};
      }
    }
    
    img {
      max-width: 37.5rem;
      width: 37.5rem;
    }
  }
`
