import React from 'react'
import { useSelector } from 'react-redux'
import { IStore } from '@store/store'
import Icons from '@icons/icons'
import { ThemeProvider } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { StyledCheckoutBackToCart } from '@components/views/checkoutPage/components/checkoutBackToCart/styles/checkoutBackToCart.styled'
// @ts-ignore
import { Routers } from '@types/routers'

import { commonStyle } from '../../../../../styles'

export function CheckoutBackToCart() {
  const navigate = useNavigate()

  const colorTheme = useSelector((state: IStore) => state.theme.colorTheme)

  const theme = {
    color: commonStyle[colorTheme].color,
    secondColor: commonStyle[colorTheme].secondColor
  }

  const setRouter = (routerName: Routers) => {
    navigate(`/${routerName}`)
  }

  const goToCart = () => {
    setRouter('cart')
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledCheckoutBackToCart>
        <div onClick={goToCart}>
          <Icons name='arrow-back' color='#828282' size='24' className='icon' />
          <p>Вернуться в корзину</p>
        </div>
      </StyledCheckoutBackToCart>
    </ThemeProvider>
  )
}
