import styled from 'styled-components'

import { commonStyle } from '../../../../../../../../../../styles'

type StyledTableItemProps = {
  $inStoke: boolean
}

export const StyledTableItem = styled.div.attrs(() => ({
  className: 'table-item',
}))<StyledTableItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 2.25rem;
  font-size: ${() => commonStyle.fonts.fs14};
  color: ${props => props.$inStoke ? props.theme.color : commonStyle.colors.grey400};
  font-weight: ${() => commonStyle.fontStyles.fw500};
  
  .art {
    padding: 0.25rem 0.5rem;
    font-size: ${() => commonStyle.fonts.fs11};
  }
  
  .name {
    padding-left: 1.25rem;
    font-size: ${() => commonStyle.fonts.fs12};
  }
  
  .item-selector {
    height: 100%;
    padding-left: 0.25rem;
    
    .t-selector {
      height: 100%;

      .selector {
        height: 100%;
        border-radius: ${() => commonStyle.radii.radius4};
        padding: 0;
        font-size: ${() => commonStyle.fonts.fs12};

        .text {
          flex-grow: 1;
          min-width: fit-content;
          padding: 0.25rem 0.5rem;
          
          p {
            min-width: fit-content;
          }
        }
      }
    }
  }
  
  .item-number {
    margin-left: 1.25rem;

    .MuiFormControl-root {

      .MuiInputBase-root {

        .MuiInputBase-input {
          font-size: ${() => commonStyle.fonts.fs12} !important;
        }
      }
    }
  }
  
  .item-min-order {
    padding-left: 1.25rem;
  }
  
  .item-full-price {
    padding-left: 1.25rem;
  }
  
  .item-sale {
    padding-left: 1.25rem;
  }

  .table-subitem {
    display: flex;
    align-items: center;
    height: 100%;
  }
  
  .center {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  
  .left {
    display: flex;
    justify-content: start;
    height: 100%;
    width: 100%;
  }
  
  .isNew {
    width: 100%;
    border-radius: ${() => commonStyle.radii.radius4};
    background-color: rgba(177, 247, 207, 1);
  }
  
  .discount {
    width: 100%;
    border-radius: ${() => commonStyle.radii.radius4};
    background-color: rgba(255, 221, 227, 1);
  }

  @media screen and (max-width: 961px) {
    .name {
      padding-left: 0.5rem;
    }

    .item-min-order {
      padding-left: 0.5rem;
    }

    .item-full-price {
      padding-left: 0.5rem;
    }

    .item-sale {
      padding-left: 0.5rem;
    }
  }
`
