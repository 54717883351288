import styled from 'styled-components'

import { commonStyle } from '../../../../styles'

export const StyledPlugPage = styled.div.attrs(() => ({
  className: 'plug-page page',
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  h2 {
    margin: 2rem 0;
    font-size: ${() => commonStyle.fonts.fs18};
    font-weight: ${() => commonStyle.fontStyles.fw600};
  }
  
  .info {
    margin: 0.5rem 0;
    font-size: ${() => commonStyle.fonts.fs16};
    font-weight: ${() => commonStyle.fontStyles.fw500};
  }
  
  .files {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
    text-align: center;
    
    h3 {
      font-size: ${() => commonStyle.fonts.fs16};
      font-weight: ${() => commonStyle.fontStyles.fw600};
    }
    
    div {
      .link {
        color: ${() => commonStyle.colors.blue};
        font-size: ${() => commonStyle.fonts.fs14};
        font-weight: ${() => commonStyle.fontStyles.fw500};
      }
    }
  }
`
